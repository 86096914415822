// src/configLoader.js
const configs = {
  'sms-demo.pinestack.eu': () => import('../config/sms.js'),
  'pia.staging.pinestack.eu': () => import('../config/staging_wfe.js'),
  'pia.qa.pinestack.eu': () => import('../config/qa_wfe.js'),
  localhost: () => import('../config/qa_wfe.js'), // Updated for localhost
  '192.168.178.103': () => import('../config/qa_wfe.js'), // Windows/Android IP
  '192.168.178.196': () => import('../config/qa_wfe.js'), // Mac/iOS IP
  'hammerbrooklyn.pinestack.eu': () => import('../config/qa_wfe.js'), // Ensure correct path and extension
  // Add other tenants as needed
}

export const loadConfig = async () => {
  const hostname = window.location.hostname
  for (const domain in configs) {
    if (hostname.includes(domain)) {
      const configModule = await configs[domain]()
      return configModule.default // Make sure to return the default export of the module
    }
  }
  throw new Error('Configuration not found for this domain: ' + hostname)
}

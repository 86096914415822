<template>
    <div>
        <v-toolbar border density="compact" color="#1A1A1A">

            <v-icon class="ml-4" size="40" @click="goBack">
                mdi-arrow-left-bold-circle
            </v-icon>
        </v-toolbar>
        <v-container class="slido-container" fluid>

            <iframe :src="slidoUrl" title="Slido Polls" class="slido-iframe" frameborder="0"></iframe>
        </v-container>
    </div>

</template>

<script>
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';


export default defineComponent({
    name: 'SlidoView',
    setup() {
        const slidoUrl = ref('https://app.sli.do/event/dq3uMBQGJELcnaCUHFDiBv'); // Replace 'XXXXXX' with your Slido event ID
        const router = useRouter();

        const goBack = () => {
            router.go(-1);
        };
        return {
            slidoUrl,
            goBack
        };

    },
});
</script>

<style scoped>
.slido-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0;
    overflow: hidden;
}

.slido-iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.custom-toolbar {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    color: #333;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
}

.custom-toolbar-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #333;
    margin-left: 10px;
    /* Spacing between logo and title */
}

.toolbar-icon {
    height: 30px;
}
</style>
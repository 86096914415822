<template>
  <div class="background">
    <Loader :loading="showLoader" />
    <v-toolbar class="custom-toolbar" dense flat>
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title class="custom-toolbar-title">Room Control</v-toolbar-title>
    </v-toolbar>

    <v-container>
      <v-row justify="center" class="icon-row">
        <v-col cols="3" class="icon-col" v-if="hasLight" @click="setActive(1)">
          <div :class="{
            'active-icon': activeIcon === 1,
            'inactive-icon': activeIcon !== 1,
          }" class="icon-container">
            <v-icon :class="{ 'small-icon': isSmallScreen }">mdi-lightbulb-outline</v-icon>
          </div>
        </v-col>
        <v-col cols="3" class="icon-col" v-if="hasBlinds" @click="setActive(2)">
          <div :class="{
            'active-icon': activeIcon === 2,
            'inactive-icon': activeIcon !== 2,
          }" class="icon-container">
            <v-icon :class="{ 'small-icon': isSmallScreen }">mdi-blinds</v-icon>
          </div>
        </v-col>
        <v-col cols="3" class="icon-col" v-if="hasVentilator" @click="setActive(3)">
          <div :class="{
            'active-icon': activeIcon === 3,
            'inactive-icon': activeIcon !== 3,
          }" class="icon-container">
            <v-icon :class="{ 'small-icon': isSmallScreen }">mdi-fan</v-icon>
          </div>
        </v-col>
        <v-col cols="3" class="icon-col" v-if="hasHeater" @click="setActive(4)">
          <div :class="{
            'active-icon': activeIcon === 4,
            'inactive-icon': activeIcon !== 4,
          }" class="icon-container">
            <v-icon :class="{ 'small-icon': isSmallScreen }">mdi-thermometer</v-icon>
          </div>
        </v-col>
      </v-row>
      <div v-if="activeIcon === 1" class="gradient-background">
        <LightControl :light="lights" />
      </div>
      <div v-if="activeIcon === 2" class="gradient-background">
        <BlindsControl :blinds="blinds" />
      </div>
      <div v-if="activeIcon === 3" class="gradient-background">
        <VentControl :vents="ventilators" />
      </div>
      <div v-if="activeIcon === 4" class="gradient-background">
        <HeatingControl :heaters="heaters" />
      </div>
    </v-container>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, onUnmounted } from 'vue'
import Loader from '@/components/general/Loader.vue'
import LightControl from '@/components/general/LightControl.vue'
import VentControl from '@/components/general/VentControl.vue'
import HeatingControl from '@/components/general/HeatingControl.vue'
import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
import { useRoute } from 'vue-router'
import { getStoredItem } from '@/services/utils.js'
import { loadConfig } from '@/configLoader'
import { getHardwareByType } from '@/controllers/BackboneAPI'
import { getPoiById, isStaingOrQa } from '@/controllers/BaseController'
import BlindsControl from '@/components/general/BlindsControl.vue'
import lodash from 'lodash'
import mqttService from '@/services/mqtt.js'
import { lightsTest, ventilationTest, blindsTest, heatersTest } from '@/tests/roomControlHardwares'

export default defineComponent({
  name: 'RoomControl',
  components: {
    Loader,
    LightControl,
    BlindsControl,
    VentControl,
    HeatingControl,
  },
  setup() {
    const logo = ref()
    const isPiaProject = ref(false)
    const showLoader = ref(false)
    const activeIcon = ref(undefined)
    const isSmallScreen = ref(false)
    const route = useRoute()
    const hardwares = ref([])
    const hasLight = ref(false)
    const hasBlinds = ref(false)
    const hasVentilator = ref(false)
    const hasHeater = ref(false)
    const lights = ref([])
    const blinds = ref([])
    const ventilators = ref([])
    const heaters = ref([])

    const setActive = iconNumber => {
      activeIcon.value = iconNumber
    }

    const checkScreenSize = () => {
      isSmallScreen.value = window.innerWidth < 600
    }

    const fetchHardwares = async poi => {
      try {
        const storedHardwares = await getStoredItem('selectedHardwares')
        if (!storedHardwares) {
          console.error('No hardwares found in storage')
          return []
        }

        const hardwareTypes = ['LIGHT', 'BLINDS', 'VENTILATOR', 'HEATER']
        const hardwareFetches = hardwareTypes.map(async type => {
          const hardwaresByType = await getHardwareByType(poi?.buildingId, type)
          // hardwaresByType.forEach(hardware => {
          //   let actions = hardware.actions
          //   if (hardware.hardwareType === 'BLINDS') {
          //     console.log(hardware)
          //     // actions.forEach(action => {
          //     //   if (action.actionType === 'scene') {
          //     //     console.log(hardware)
          //     //   }
          //     // })
          //   }
          // })
          return storedHardwares.filter(hardware => hardware.type === type).map(hardware => lodash.find(hardwaresByType, { id: hardware.id }))
        })

        const hardwareResults = await Promise.all(hardwareFetches)
        console.log(hardwareResults)
        return hardwareResults.flat()
      } catch (error) {
        console.error('Error fetching hardwares:', error)
        return []
      }
    }

    onMounted(async () => {
      try {
        const [logoImg, config] = await Promise.all([getToolbarLogoImage(), loadConfig()])
        logo.value = logoImg
        console.log(await isStaingOrQa())
        isPiaProject.value = await isStaingOrQa()

        showLoader.value = true
        checkScreenSize()
        window.addEventListener('resize', checkScreenSize)

        await mqttService.connect()

        const poiId = route.params.poi_id
        if (!poiId) {
          console.error('No poi_id found in route parameters')
          return
        }

        const poi = await getPoiById(poiId)
        let poiHardwares = await fetchHardwares(poi)
        if (poiHardwares.length === 0) {
          console.error('No hardwares found for POI')
          return
        }

        if (isPiaProject.value) {
          poiHardwares = []
          poiHardwares = [...lightsTest, ...ventilationTest, ...blindsTest, ...heatersTest]
        }

        const types = poiHardwares.map(hardware => hardware.hardwareType)

        hasLight.value = types.includes('LIGHT')
        hasBlinds.value = types.includes('BLINDS')
        hasVentilator.value = types.includes('VENTILATOR')
        hasHeater.value = types.includes('HEATER')
        hardwares.value = poiHardwares

        if (hasLight.value) {
          const lightsHardware = poiHardwares.filter(hardware => hardware.hardwareType === 'LIGHT')

          lightsHardware.forEach((lightHardware, index) => {
            const actions = lightHardware.actions
            const updatedHardware = { ...lightHardware }

            actions.forEach(action => {
              if (action.actionType === 'scene') {
                updatedHardware.hasScene = true
                updatedHardware.sceneOptions = action.spec.def
              } else if (action.actionType === 'brightness') {
                updatedHardware.brightnessOptions = action.spec.def[0]
                updatedHardware.hasBrightness = true
              } else if (action.actionType === 'temperature') {
                updatedHardware.temperatureOptions = action.spec.def[0]
                updatedHardware.hasTemperature = true
              } else if (action.actionType === 'switch') {
                updatedHardware.switchOptions = action.spec.def
                updatedHardware.hasSwitch = true
              }
            })

            lightsHardware[index] = updatedHardware
          })
          lights.value = lightsHardware
        }

        if (hasBlinds.value) {
          const blindsHardware = poiHardwares.filter(hardware => hardware.hardwareType === 'BLINDS')
          blindsHardware.forEach(blind => {
            let actions = blind.actions
            actions.forEach(action => {
              if (action.actionType === 'switch') {
                blind.hasSwitch = true
                blind.switchOptions = action.spec.def[0]
              } else if (action.actionType === 'position') {
                blind.hasPosition = true
                blind.positionOptions = action.spec.def[0]
              } else if (action.actionType === 'angle') {
                blind.hasAngle = true
                blind.angleOptions = action.spec.def
              } else if (action.actionType === 'scene') {
                blind.hasScene = true
                blind.sceneOptions = action.spec.def
              }
            })
          })
          blinds.value = blindsHardware
        }
        if (hasVentilator.value) {
          const ventilatorHardware = poiHardwares.filter(hardware => hardware.hardwareType === 'VENTILATOR')
          let vents = []
          ventilatorHardware.forEach(ventilator => {
            let actions = ventilator.actions
            actions.forEach(action => {
              if (action.actionType === 'motor') {
                ventilator.hasMotor = true
                let defs = action.spec.def
                let index = 0
                ventilator.ventOptions = []
                for (let def in defs) {
                  let action = {
                    id: index++,
                    value: def,
                    label: def,
                    hwValue: defs[def].value,
                  }
                  ventilator.ventOptions.push(action)
                }
                vents.push(ventilator)
              }
            })
          })
          ventilators.value = vents
        }
        if (hasHeater.value) {
          const heaterHardware = poiHardwares.filter(hardware => hardware.hardwareType === 'HEATER')
          let heatersHardwares = []
          heaterHardware.forEach(heater => {
            let actions = heater.actions
            actions.forEach(action => {
              if (action.actionType === 'temperature') {
                heater.hasTemperature = true
                heater.temperatureOptions = action.spec.def[0]
              }
              heatersHardwares.push(heater)
            })
          })
          heaters.value = heatersHardwares
        }

        if (types.includes('LIGHT')) {
          setActive(1)
        } else if (types.includes('BLINDS')) {
          setActive(2)
        } else if (types.includes('VENTILATOR')) {
          setActive(3)
        } else if (types.includes('HEATER')) {
          setActive(4)
        }
      } catch (error) {
        console.error('Error processing POI:', error)
      } finally {
        showLoader.value = false
        //test//
        setActive(1)
      }
    })

    onUnmounted(() => {
      window.removeEventListener('resize', checkScreenSize)
      mqttService.disconnect()
    })

    return {
      logo,
      showLoader,
      activeIcon,
      setActive,
      isSmallScreen,
      hardwares,
      hasLight,
      hasBlinds,
      hasVentilator,
      hasHeater,
      lights,
      blinds,
      ventilators,
      heaters,
    }
  },
})
</script>

<style scoped>
.background {
  background: linear-gradient(135deg, #f0f4f8 0%, #d9e2ec 100%);
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.gradient-background {
  background: linear-gradient(135deg, #f0f4f8 0%, #d9e2ec 100%);
  padding: 16px;
  border-radius: 12px;
}

.custom-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
  /* Slight transparency for elegance */
  backdrop-filter: blur(10px);
  /* Blur background content */
  color: #333333;
  /* Dark text color for contrast */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  /* Soft shadow for elevation */
  border-radius: 8px;
  /* Rounded corners */
  padding: 8px 16px;
  /* Adjusted padding for spacing */
}

.custom-toolbar-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #333333;
  /* Matching text color */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 120px);
  /* Adjust based on available space */
}

.toolbar-icon {
  height: 30px;
  align-self: center;
  margin-left: 1rem;
}

.icon-row {
  margin-top: 20px;
}

.icon-col {
  text-align: center;
}

.icon-container {
  display: inline-block;
  border-radius: 50%;
  padding: 10px;
  font-size: 36px;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.icon-container:hover {
  transform: scale(1.1);
}

.active-icon {
  color: #ffffff;
  background-color: black;
}

.inactive-icon {
  color: #ffffff;
  background-color: #888888;
}

.small-icon {
  font-size: 35px !important;
}

@media (max-width: 600px) {
  .icon-container {
    padding: 5px;
    font-size: 24px;
  }

  .toolbar-icon {
    height: 20px;
  }
}
</style>
<!-- RoomControl Component

This component provides a user interface for controlling various room hardware such as lights, blinds, ventilators, and heaters. The main features and functionalities offered by this component are as follows:

1. Loader: A loading spinner is displayed when data is being fetched or processed, providing visual feedback to the user that an operation is in progress.

2. Toolbar: The toolbar at the top includes a logo and a title "Room Control". The logo is dynamically loaded based on the project configuration.

3. Icon Row: Below the toolbar, there is a row of icons representing different types of hardware that can be controlled. The icons include:
  - Light (represented by a lightbulb icon)
  - Blinds (represented by a blinds icon)
  - Ventilator (represented by a fan icon)
  - Heater (represented by a thermometer icon)

  Each icon is displayed conditionally based on the availability of the corresponding hardware in the room. Clicking on an icon sets it as the active control, highlighted with a different background color.

4. Control Panels: Depending on the active icon, a corresponding control panel is displayed below the icons. The control panels include:
  - LightControl: Allows users to control lights, including options for scenes, brightness, temperature, and switch.
  - BlindsControl: Allows users to control blinds, including options for switch, position, angle, and scene.
  - VentControl: Allows users to control ventilators, including motor speed options.
  - HeatingControl: Allows users to control heaters, including temperature options.

5. Responsive Design: The component adjusts its layout based on the screen size. For smaller screens, the icons and toolbar elements are resized to ensure a good user experience.

6. Dynamic Data Loading: On component mount, the necessary data such as hardware configurations and project settings are fetched asynchronously. The component also listens for window resize events to adjust the layout dynamically.

7. Error Handling: If there are any errors during data fetching or processing, they are logged to the console for debugging purposes.

8. MQTT Integration: The component connects to an MQTT service to receive real-time updates for the hardware controls.

Overall, this component provides a comprehensive and user-friendly interface for controlling various room hardware, enhancing the overall user experience by offering intuitive controls and real-time feedback. -->

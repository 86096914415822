<template>
  <div class="vouchers">

    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <v-btn icon>
        <v-icon size="40" @click="goBack">
          mdi-arrow-left-bold-circle
        </v-icon>
      </v-btn>
      <v-toolbar-title class="custom-toolbar-title">
        <!-- Align "Functions" title next to the logo -->
        {{ 'Vouchers' }}
      </v-toolbar-title>
      <img :src="logo" class="toolbar-icon" />
    </v-toolbar>

    <v-container class="mt-3">
      <v-row>
        <v-col cols="12" md="4">
          <v-card class="progress-card mx-auto" elevation="3">
            <v-card-title class="text-h6">Progress Bars</v-card-title>
            <v-divider></v-divider>
            <div v-for="(progress, index) in progressData" :key="index" class="progress-item">
              <div class="progress-title">{{ progress.title }}</div>
              <div class="progress-subtitle">{{ progress.subtitle }}</div>
              <v-progress-linear :color="progress.color" height="10" :model-value="progress.value" striped
                class="mb-3 progress-bar"></v-progress-linear>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="8">
          <v-row class="voucher-row">
            <v-col v-for="voucher in vouchers" :key="voucher.id" cols="12" md="6" lg="4" class="voucher-col">
              <v-card class="voucher-card mx-auto" elevation="5">
                <div class="voucher-content">
                  <div class="voucher-details">
                    <v-avatar size="50" class="mr-3">
                      <v-icon large>{{ getVoucherIcon(voucher.type) }}</v-icon>
                    </v-avatar>
                    <v-card-title class="text-h6">{{ voucher.name }}</v-card-title>
                    <v-card-subtitle style="white-space: break-spaces">{{ voucher.description }}</v-card-subtitle>
                    <v-card-actions>
                      <v-btn v-if="!voucher.expired" class="claim-button ms-2" size="small" text
                        @click="claimVoucher(voucher)">
                        Claim
                      </v-btn>
                      <v-btn v-if="voucher.expired" class="expired-button ms-2" size="small" disabled>
                        Expired
                      </v-btn>
                    </v-card-actions>
                  </div>
                  <v-avatar class="ma-3 qr-code-avatar" size="75">
                    <v-img :src="qrcode" contain></v-img>
                  </v-avatar>
                </div>
                <v-divider></v-divider>
                <div class="days-left text-end">
                  {{ voucher.expired ? 'Expired' : `${voucher.daysLeft} days` }}
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
import qrcode from '@/assets/General/qrcode.jpg'
import { useRouter } from 'vue-router'
import { vouchers } from '@/dummydata/userOfficeData'

export default defineComponent({
  name: 'Vouchers',
  setup() {
    const logo = ref('')
    const router = useRouter()

    onMounted(async () => {
      logo.value = await getToolbarLogoImage()
    });

    const goBack = () => {
      router.go(-1);
    };

    const progressData = ref([
      { title: 'Get Free Lunch', subtitle: '2 days more to go', value: 40, color: 'light-blue' },
      { title: 'Early Leave', subtitle: '20 days more to go', value: 10, color: 'light-green-darken-4' },
      { title: 'Work from Home Day', subtitle: '10 days more to go', value: 30, color: 'deep-orange' },
      { title: 'Extra Break', subtitle: '3 days more to go', value: 60, color: 'lime' },
      { title: 'Gym Access', subtitle: '15 days more to go', value: 50, color: 'purple' },
      { title: 'Parking Spot', subtitle: '5 days more to go', value: 80, color: 'cyan' }
    ]);

    const getVoucherIcon = type => {
      switch (type) {
        case 'lunch': return 'mdi-food';
        case 'leave': return 'mdi-clock-out';
        case 'work_from_home': return 'mdi-home';
        case 'break': return 'mdi-coffee';
        case 'gym': return 'mdi-dumbbell';
        case 'parking': return 'mdi-car';
        default: return 'mdi-ticket';
      }
    };

    const claimVoucher = voucher => {
      if (!voucher.expired) {
        console.log(`Voucher ${voucher.name} claimed!`);
      }
    };

    return {
      logo,
      vouchers,
      getVoucherIcon,
      claimVoucher,
      qrcode,
      router,
      progressData,
      goBack
    }
  }
})
</script>

<style scoped>
.vouchers {
  padding: 1rem;
}

.custom-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  color: #333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.custom-toolbar-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #333;
  margin-left: 10px;
  /* Spacing between logo and title */
}

.toolbar-icon {
  height: 30px;
}

.progress-card {
  max-width: 100%;
  margin: 10px auto;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.progress-card:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.progress-title {
  font-weight: bold;
  margin-bottom: 4px;
}

.progress-subtitle {
  font-size: 0.8rem;
  margin-bottom: 8px;
}

.progress-bar {
  transition: all 0.3s;
}

.voucher-card {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 16px;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 250px;
  /* Ensures all cards have the same height */
}

.voucher-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.voucher-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.qr-code-avatar {
  max-width: 85px;
  max-height: 85px;
  width: 100%;
  height: auto;
}

.days-left {
  font-weight: bold;
  padding: 16px;
}

.claim-button {
  background-color: #dc143c;
  color: white;
  transition: background-color 0.3s;
}

.claim-button:hover {
  background-color: #a10f2b;
}

.expired-button {
  background-color: grey;
  color: white;
}

.voucher-row {
  margin: 0;
}

.voucher-col {
  padding: 8px;
}

@media (max-width: 768px) {
  .v-card {
    flex-direction: column;
  }

  .voucher-details {
    text-align: center;
  }

  .qr-code-avatar {
    max-width: 60px;
    max-height: 60px;
  }
}

@media (max-width: 600px) {
  .voucher-card {
    padding: 8px;
  }

  .qr-code-avatar {
    max-width: 50px;
    max-height: 50px;
  }
}
</style>
<!-- Vouchers Component
This component provides a user interface for managing and viewing vouchers. The main features include a toolbar, progress bars, and a list of vouchers that users can claim.

Toolbar
The toolbar at the top includes a back button, a title, and a logo. The back button allows users to navigate to the previous page. The title "Vouchers" is displayed next to the logo, which is dynamically loaded.

Progress Bars
On the left side, there is a card displaying progress bars for various goals. Each progress bar has a title, subtitle, and a visual representation of the progress. The progress bars are color-coded and provide users with a quick overview of their progress towards different rewards.

Voucher List
The right side contains a list of vouchers. Each voucher is displayed in a card format with the following details:
- An icon representing the type of voucher
- The name of the voucher
- A description of the voucher
- A button to claim the voucher if it is not expired
- A disabled button indicating the voucher is expired if applicable
- A QR code image for the voucher
- The number of days left before the voucher expires or an "Expired" label

Users can interact with the vouchers by clicking the "Claim" button, which triggers the claimVoucher function. If the voucher is expired, the button is disabled and labeled "Expired".

Visual Feedback
The component provides visual feedback through hover effects on cards and buttons. When a user hovers over a card, it slightly enlarges and the shadow intensifies, indicating it is clickable. The "Claim" button changes color on hover to provide a visual cue that it is interactive.

Overall, this component enhances the user experience by providing a clear and interactive interface for managing vouchers. Users can easily track their progress towards rewards and claim vouchers with a single click. -->

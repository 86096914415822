<template>
    <div class="users-management">
        <!-- Loader and Toolbar -->
        <Loader :loading="showLoader" />
        <v-toolbar class="custom-toolbar mb-4" flat dense>
            <v-btn icon @click="goBack">
                <v-icon size="40">mdi-arrow-left-bold-circle</v-icon>
            </v-btn>
            <v-toolbar-title class="custom-toolbar-title">
                {{ 'Admin Users Management' }}
            </v-toolbar-title>
            <img :src="logo" class="toolbar-icon" />
        </v-toolbar>

        <!-- Tile Section -->
        <v-container fluid class="tile-container">
            <v-row dense>
                <v-col cols="12" sm="4">
                    <v-card class="tile-card" elevation="5">
                        <v-card-title>
                            <v-icon style="color: darkcyan" class="tile-icon">mdi-account-settings</v-icon>
                            <span class="tile-title">Users</span>
                        </v-card-title>
                        <v-card-text class="tile-description">
                            Administration of app users and content managers.
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="white" class="tile-button" dark @click="navigateTo('UsersList')">Manage
                                users</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-card class="tile-card" elevation="3">
                        <v-card-title>
                            <v-icon style="color: slateblue" class="tile-icon">mdi-account-group</v-icon>
                            <span class="tile-title">Groups</span>
                        </v-card-title>
                        <v-card-text class="tile-description">
                            Administration and permissions for user groups.
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="white" class="tile-button" dark @click="navigateTo('GroupsList')">Manage
                                groups</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-card class="tile-card" elevation="3">
                        <v-card-title>
                            <v-icon style="color: tomato" class="tile-icon">mdi-cog</v-icon>
                            <span class="tile-title">Roles</span>
                        </v-card-title>
                        <v-card-text class="tile-description">
                            Administration of roles and role permissions.
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="white" class="tile-button" dark @click="navigateTo('RolesList')">Manage
                                roles</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { ref, onMounted, defineComponent } from 'vue'
import { debounce } from 'lodash'
import Loader from '@/components/general/Loader.vue'
import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
import { useRouter } from 'vue-router'

export default defineComponent({
    name: 'UsersManagement',
    components: {
        Loader,
    },
    setup() {
        const showLoader = ref(true)
        const router = useRouter()
        const logo = ref('')

        const goBack = () => {
            window.history.back()
        }

        const navigateTo = (routeName) => {
            router.push({ name: routeName })
        }

        const resizeListener = debounce(() => (window.innerWidth), 200)

        onMounted(async () => {
            try {
                showLoader.value = true
                logo.value = await getToolbarLogoImage()
                window.addEventListener('resize', resizeListener)
                resizeListener()
                showLoader.value = false
            } catch (error) {
                console.error('Failed to load data:', error)
                showLoader.value = false
            }
        })

        return {
            showLoader,
            logo,
            router,
            goBack,
            navigateTo,
        }
    },
})
</script>

<style scoped>
.users-management {
    padding: 1rem;
}

.custom-toolbar {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    color: #333;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
}

.custom-toolbar-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #333;
    margin-left: 10px;
}

.toolbar-icon {
    height: 30px;
}

.tile-container {
    margin-top: 20px;
}

.tile-card {
    background-color: #f9f9f9;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    padding: 20px;
}

.tile-icon {
    font-size: 48px;
    color: #333;
}

.tile-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-left: 8px;
    color: #333;
}

.tile-description {
    color: #666;
    margin-top: 10px;
    font-size: 0.95rem;
}

.tile-button {
    margin-top: 10px;
    font-size: 0.875rem;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Responsive styles */
@media (max-width: 600px) {
    .tile-card {
        padding: 15px;
    }

    .tile-icon {
        font-size: 40px;
    }

    .tile-title {
        font-size: 1.1rem;
    }

    .tile-description {
        font-size: 0.875rem;
    }

    .tile-button {
        font-size: 0.75rem;
    }
}
</style>
<!-- UsersManagement Component
This component provides an interface for managing users, groups, and roles within the application. It is designed to be used by administrators to efficiently handle user-related tasks.

The component consists of several key sections:

1. Loader and Toolbar:
    - A Loader component is displayed while data is being loaded.
    - A toolbar at the top includes a back button, a title, and a logo. The back button allows users to navigate to the previous page.

2. Tile Section:
    - This section contains three tiles, each representing a different management area: Users, Groups, and Roles.
    - Each tile includes an icon, a title, a description, and a button to navigate to the respective management page.

    - Users Tile:
      - Icon: mdi-account-settings
      - Title: Users
      - Description: Administration of app users and content managers.
      - Button: Clicking the "Manage users" button navigates to the UsersList page.

    - Groups Tile:
      - Icon: mdi-account-group
      - Title: Groups
      - Description: Administration and permissions for user groups.
      - Button: Clicking the "Manage groups" button navigates to the GroupsList page.

    - Roles Tile:
      - Icon: mdi-cog
      - Title: Roles
      - Description: Administration of roles and role permissions.
      - Button: Clicking the "Manage roles" button navigates to the RolesList page.

Visual Feedback:
- The Loader component provides visual feedback while data is being loaded.
- The toolbar and tiles are styled to provide a clean and user-friendly interface.
- Buttons provide visual feedback on hover and click, enhancing the user experience.

Dynamic Behaviors:
- The back button uses the goBack method to navigate to the previous page.
- The navigateTo method is used to navigate to different management pages based on the button clicked.
- The resizeListener method adjusts the layout based on the window size, ensuring a responsive design.

Overall, this component enhances the user experience by providing a clear and organized interface for managing users, groups, and roles within the application. -->

<template>
  <v-container fluid class="user-bookings-list">
    <v-btn v-if="selectedReservations.length > 0" :small="isMobile" fab dark color="black" class="fixed-btn-delete"
      @click="showDeleteBookingsDialog = true">
      <v-icon>mdi-trash-can</v-icon>
    </v-btn>
    <!-- <v-btn :small="isMobile" fab dark color="black" class="fixed-btn" @click="toggleFilterDialog">
      <v-icon>mdi-filter</v-icon>
    </v-btn> -->
    <Loader :loading="showLoader" />

    <v-row align="center" justify="start">
      <v-col class="d-flex align-center" style="max-width: 300px">
        <v-select v-model="selectedDateRange" :items="dateOptions" label="Date Range:" density="compact" variant="solo"
          id="dateRangePicker" ref="dateRangePicker" style="
            background: transparent;
            width: 100%;
            font-size: 0.9rem;
            border-radius: 8px;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
          " :menu-props="{ closeOnClick: true }" @update:modelValue="onDateRangeChange" hide-details>
          <!-- <template #item="{ item }">
            <span
              @click="onDateRangeChange(item)"
              style="padding: 8px 12px; display: block; transition: background-color 0.3s; cursor: pointer; width: 100%; text-align: left;"
            >
              {{ item }}
            </span>
          </template>
<template #selection="{ item }">
            <v-chip v-if="item === 'Custom'" size="small">
              Custom: {{ `${moment(dates?.start).format("DD-MM-YY")} ► ${moment(dates?.end).format("DD-MM-YY")}` }}
            </v-chip>
            <v-chip v-else size="small">
              {{ item }}
            </v-chip>
          </template> -->
        </v-select>
        <v-btn :small="isMobile" fab dark color="black" class="ml-2" @click="toggleFilterDialog">
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="dateRangeDialog" persistent max-width="300px">
      <v-card style="background-color: #1a202c">
        <v-card-title></v-card-title>
        <v-card-text>
          <DatePicker is-range :min="new Date()" :max="maxDate" ref="rangeDatePicker" v-model.range="dates" color="red"
            mode="date" is-dark></DatePicker>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn plain color="white" style="background-color: #ff5252" @click="discardRange">{{ $t('booking.cancel')
            }}</v-btn>
          <v-btn plain style="background-color: #4caf50; color: white" @click="confirmRange">OK {{ $t('booking.ok')
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card class="flexcard" style="border-radius: 1px; margin-top: 1rem" v-if="reservations?.length <= 0">
      <v-card-title class="no_booking_text">{{ $t('booking.no-booking') }}</v-card-title>
      <v-img :src="isMobile ? imageSrcMobile : imageSrcDesktop" class="white--text align-end" height="100vh"
        style="background-size: cover; background-position: center; background: black"></v-img>
      <v-btn x-large @click="goToBookings()" large depressed class="no_booking_create">{{
        $t('booking.book-now-tab') }}</v-btn>
    </v-card>

    <v-card>
      <v-dialog v-model="filterDialogVisible" max-width="500px">
        <v-card>
          <v-card-title>{{ $t('booking.filter') }}</v-card-title>
          <v-card-text>
            <v-select :items="categories" item-value="name" item-title="name" density="compact"
              :label="$t('office.category')" @change="filterChange('changeCategory', selectedCategory)"
              v-model="selectedCategory" return-object></v-select>
          </v-card-text>
          <v-card-text>
            <v-select :items="floors" item-value="name" item-title="name" density="compact" :label="$t('office.floor')"
              @change="filterChange('changeFloor', selectedFloor)" v-model="selectedFloor" return-object></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="cancelFilter">{{ $t('booking.cancel') }}</v-btn>
            <v-btn color="primary" text @click="applyFilters">{{ $t('booking.apply-filter') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title>Dialog Heading</v-card-title>
          <v-card-text>Selected Key Dialog</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <div class="booking-card" v-for="reservation in reservations" :key="reservation.id"
      @click="handeReservation(reservation)">
      <div class="card-header" style="border: inset">
        <div class="button-container">
          <button class="styled-btn">
            {{ windowWidth < 440 ? moment(reservation.startTime).format('ddd') :
              moment(reservation.startTime).format('dddd') }} </button>
              <button class="styled-btn" v-if="reservation.recurringId === null">
                {{ windowWidth < 440 ? moment(reservation.startTime).format('DD.MM.YY') :
                  moment(reservation.startTime).format('DD.MM.YYYY') }} </button>
                  <button class="styled-btn">
                    {{ moment(reservation.startTime).format('HH:mm') + ' - ' +
                      moment(reservation.endTime).format('HH:mm')
                    }}
                  </button>
        </div>
      </div>
      <div class="card-header" style="border: inset; background-color: black !important"
        v-if="reservation.recurringId !== null">
        <div class="button-container">
          <button class="styled-btn" style="background-color: firebrick !important">
            {{ moment(reservation.startTime).format('DD.MM.YYYY') }}
          </button>
          <div class="text-center">
            <v-icon size="large" style="color: white">mdi-chevron-right</v-icon>
          </div>
          <button class="styled-btn" style="background-color: firebrick !important">
            {{ moment(reservation.recurrence.until).format('DD.MM.YYYY') }}
          </button>
        </div>
      </div>
      <div class="card-body" style="border: inset">
        <div class="info-container">
          <div class="info-item">
            <v-icon left>mdi-calendar</v-icon>
            <span class="text">{{ reservation.title ? reservation.title : reservation.appointment.title }}</span>
          </div>
          <div class="info-item">
            <v-icon left>mdi-seat</v-icon>
            <span class="text">{{ reservation.poi.name }}</span>
          </div>
          <div class="info-item">
            <v-icon left>mdi-stairs</v-icon>
            <span class="text">{{ reservation.floorName + ', Floor # ' + reservation.floor.number }}</span>
          </div>
          <div class="info-item">
            <v-icon left>mdi-door-closed</v-icon>
            <span class="text">{{ reservation.poiCategory }}</span>
          </div>
        </div>
      </div>
    </div>

    <v-container v-if="showBookingDetail">
      <v-dialog v-model="showBookingDetail" width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2" style="font-weight: bold; height: 3.5rem">
            <v-row dense class="align-center justify-space-between">
              <v-btn style="font-size: 0.7rem; width: auto; align-items: center; height: 30px; box-shadow: none"
                @click="deleteConfirmation(selectedReservation)">
                <v-icon color="red" style="font-size: 1.2rem; margin-right: 0.2rem">mdi-delete</v-icon>
                <span>Delete</span>
              </v-btn>
              <v-btn :disabled="!selectedReservation?.appointment?.id"
                style="font-size: 0.7rem; width: auto; align-items: center; height: 30px; box-shadow: none"
                @click="editBooking(selectedReservation)">
                <v-icon style="font-size: 1.2rem; margin-right: 0.2rem">mdi-pencil</v-icon>
                <span>Edit</span>
              </v-btn>
              <v-icon @click="showBookingDetail = false" style="cursor: pointer">mdi-close</v-icon>
            </v-row>
          </v-card-title>
          <v-card-text class="text-body-1" style="padding: 16px; font-size: 1rem">
            <div v-if="!selectedReservation?.appointment?.id" class="text-center red--text mb-4">
              <div class="text-h6">The booking has been corrupted</div>
              <div class="text-body-2">Please delete and recreate the booking</div>
            </div>
            <div>
              <div class="my-2 font-weight-bold">
                {{ selectedReservation?.title ?? selectedReservation?.appointment?.title }}
              </div>
              <div class="my-2">
                {{ moment(selectedReservation?.startTime).format('dddd, DD.MM.YYYY') }}
              </div>
              <div class="my-2">
                {{ 'From ' + moment(selectedReservation?.startTime).format('HH:mm') + ' To ' +
                  moment(selectedReservation?.endTime).format('HH:mm') }}
              </div>
              <div>
                {{ selectedReservation?.creatorEmail }}
              </div>
            </div>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-title class="font-weight-bold">Place Name</v-card-title>
          <v-card-text class="text-body-1" style="padding: 16px">
            <v-row align="start" class="mx-0">
              <div class="black--text" style="margin-bottom: 1rem">
                {{
                  selectedReservation.poiName && selectedReservation.poiName.match(/\{\d+\}$/)
                    ? selectedReservation.poiName.replace(/\s*\{\d+\}$/, '')
                    : selectedReservation.poiName
                }}
              </div>
            </v-row>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-title class="font-weight-bold">People</v-card-title>
          <v-card-text class="text-body-1" style="padding: 16px">
            <v-row align="start" class="mx-0">
              <div class="black--text" style="margin-bottom: 1rem">
                {{ selectedReservation?.personCount }}
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script>
import { ref, computed, onMounted, watch, nextTick, onUnmounted } from 'vue'
import lodash from 'lodash'
import { useRouter } from 'vue-router'
import moment from 'moment'
import { useOAuthService } from '@/services/OAuthService' // Assuming you have an OAuth service for handling authentication

import {
  deleteAllAppointments,
  deleteAllReservations,
  getReservationsByCurrentUser,
  getAllAppointments,
  getUserProfile,
  getBuildings,
  deleteRecurrenceAppointment,
  deleteRecurrenceReservation,
  getPoiById,
  deleteAppointment,
  deleteReservation,
  getFloorById,
  getFloors,
  getPoiCategories,
  getAppointmentById,
  getReservationById,
  getPoiCategoryById,
  getReservationsByCurrentUserByTime,
} from '@/controllers/BaseController'
import Loader from '@/components/general/Loader.vue'
import CreateEditBooking from '@/components/general/CreateEditBooking.vue'
import Swal from 'sweetalert2'
import { DatePicker } from 'v-calendar'
import imageSrcDesktop from '@/assets/General/no_booking_desktop.webp'
import imageSrcMobile from '@/assets/General/no_booking_mobile.webp'

export default {
  name: 'BookingsList',
  components: {
    Loader,
    DatePicker,
  },
  setup() {
    const OAuthService = useOAuthService()
    const router = useRouter()
    const showDatePicker = ref(false)
    const selectedReservationTitles = ref([])
    const showDeleteBookingsDialog = ref(false)
    const selectedReservations = ref([])
    const dateRangeDialog = ref(false)
    const pressTimer = ref(null)
    const dialog = ref(false)
    const dates = ref({
      start: new Date(), // Default start date
      end: null,
    })
    const date = ref(new Date())
    const dateRangeMenuVisible = ref(false)
    const dateOptions = ref(['All', 'Today', 'Tomorrow', 'Current Week', 'Next Week', 'Current Month', 'Next Month', 'Custom'])
    const dateDisplayText = ref('Select Start and End Date')
    const selectedDateRange = ref('All')
    const timezone = ref('')
    const filterDialogVisible = ref(false) // control visibility of filter dialog
    const startDate = ref(null)
    const endDate = ref(null)
    const startDateMenu = ref(false)
    const endDateMenu = ref(false)
    const categories = ref([])
    const selectedCategory = ref({})
    const selectedFloor = ref({})
    const floors = ref([])
    const selected = ref({})
    const startDateDialogVisible = ref(false)
    const showFilterDialog = ref(false)
    const menu = ref(false)
    const selectedKey = ref('')
    const items = ref([
      { title: 'Category', key: 'category' },
      { title: 'Floor', key: 'floor' },
      { title: 'Date Time', key: 'date-time' },
      { title: 'Capacity', key: 'capacity' },
    ])
    const bookings = ref([])
    const windowWidth = ref(0)
    const showNoBookings = ref(false)
    const reservations = ref([])
    const appointments = ref([])
    const currentUserProfile = ref({})
    const buildings = ref([])
    const showLoader = ref(true)
    const showBookingDetail = ref(false)
    const showEditBooking = ref(false)
    const selectedReservation = ref({})
    const filters = ref([])
    const originalFilters = ref({
      selectedFloor: '',
      selectedCategory: '',
      dates: ['', ''],
      selectedDateRange: '',
    })
    const originalReservations = ref([])
    const categoryChanged = ref(false)
    const floorChanged = ref(false)
    const dateChanged = ref(false)
    const justLongPressed = ref(false)

    const discardRange = () => {
      selectedDateRange.value = originalFilters.value.selectedDateRange
      dates.value = originalFilters.value.dates
      dateRangeDialog.value = false
    }

    const confirmRange = () => {
      const now = moment()
      const today = moment().startOf('day')

      if (moment(dates.value.start).isSame(today, 'day')) {
        dates.value.start = now.toDate()
        dates.value.end = moment(dates.value.end).hour(20).minute(0).second(0).millisecond(0).toDate()
      } else {
        dates.value.start = moment(dates.value.start).hour(6).minute(0).second(0).millisecond(0).toDate()
        dates.value.end = moment(dates.value.end).hour(20).minute(0).second(0).millisecond(0).toDate()
      }
      originalFilters.value.selectedDateRange = selectedDateRange.value
      originalFilters.value.dates = dates.value
      dateRangeDialog.value = false
      fetchPoisList()
    }

    const handeReservation = reservation => {
      let booking = JSON.parse(JSON.stringify(reservation))
      bookingDetail(booking)
    }

    const isSelected = reservation => {
      return selectedReservations.value.some(r => r.id === reservation.id)
    }

    const cancelFilter = () => {
      selectedFloor.value = originalFilters.value.selectedFloor
      selectedCategory.value = originalFilters.value.selectedCategory
      dates.value = originalFilters.value.dates
      // selectedDateRange.value = originalFilters.value.selectedDateRange
      filterDialogVisible.value = false
    }

    const filterChange = (type, item) => {
      let obj = { [type]: item }
      filters.value.push(obj)
      let seen = new Set()
      let filtered = lodash.filter(lodash.reverse(filters.value), item => {
        let key = Object.keys(item)[0]
        return seen.has(key) ? false : seen.add(key)
      })
      filters.value = filtered
    }

    const resetCustomRangeDate = () => {
      dates.value.start = new Date()
      dates.value.end = moment(dates.value.start).add(1, 'days').toDate()
      originalFilters.value.selectedDateRange = selectedDateRange.value
      originalFilters.value.dates = dates.value
    }

    const onDateRangeChange = item => {
      if (item) {
        selectedDateRange.value = item
      }
      dateChanged.value = true
      switch (selectedDateRange.value) {
        case 'All':
          dates.value = { start: null, end: null }
          dateRangeDialog.value = false
          fetchPoisList()
          break
        case 'Today':
          dates.value = {
            start: moment().toDate(),
            end: moment().endOf('day').toDate(),
          }
          dateRangeDialog.value = false
          fetchPoisList()
          break
        case 'Tomorrow':
          dates.value = {
            start: moment().add(1, 'day').startOf('day').toDate(),
            end: moment().add(1, 'day').endOf('day').toDate(),
          }
          dateRangeDialog.value = false
          fetchPoisList()
          break
        case 'Current Week':
          dates.value = {
            start: moment().toDate(),
            end: moment().endOf('week').toDate(),
          }
          dateRangeDialog.value = false
          fetchPoisList()
          break
        case 'Next Week':
          dates.value = {
            start: moment().add(1, 'week').startOf('week').toDate(),
            end: moment().add(1, 'week').endOf('week').toDate(),
          }
          dateRangeDialog.value = false
          fetchPoisList()
          break
        case 'Current Month':
          dates.value = {
            start: moment().toDate(),
            end: moment().endOf('month').toDate(),
          }
          dateRangeDialog.value = false
          fetchPoisList()
          break
        case 'Next Month':
          dates.value = {
            start: moment().add(1, 'month').startOf('month').toDate(),
            end: moment().add(1, 'month').endOf('month').toDate(),
          }
          dateRangeDialog.value = false
          fetchPoisList()
          break
        case 'Custom':
          resetCustomRangeDate()
          dateRangeDialog.value = true
          break
        default:
          dateRangeDialog.value = false
          break
      }
    }

    const toggleFilterDialog = () => {
      filterDialogVisible.value = !filterDialogVisible.value
      if (
        originalFilters.value.selectedFloor !== undefined &&
        originalFilters.value.selectedCategory !== undefined &&
        dates.value[0] !== '' &&
        dates.value[1] !== ''
      ) {
        originalFilters.value.selectedFloor = selectedFloor.value
        originalFilters.value.selectedCategory = selectedCategory.value
      }
    }

    const applyFilters = () => {
      filters.value.forEach(filter => {
        let key = Object.keys(filter)[0]
        if (key === 'changeFloor') {
          changeFloor(filter['changeFloor'])
        } else if (key === 'changeCategory') {
          changeCategory(filter['changeCategory'])
        }
      })
      filterDialogVisible.value = false
      showLoader.value = true
      fetchPoisList()
    }

    const changeCategory = category => {
      categoryChanged.value = true
      selectedCategory.value = lodash.find(categories.value, item => item.name === category)
    }

    const changeFloor = floor => {
      floorChanged.value = true
      selectedFloor.value = lodash.find(floors.value, item => item.name === floor)
    }

    const goToBookings = () => {
      let BookNowTab = document.querySelectorAll('[tabindex="0"]')[0]?.getElementsByTagName('div')[0]
      if (BookNowTab !== undefined || BookNowTab !== null) {
        BookNowTab.click()
      } else {
        window.location = window.location.href.substr(0, window.location.href.indexOf('#'))
      }
    }

    const bookingDetail = async booking => {
      let reservation = booking
      selectedReservation.value = reservation
      console.log(reservation)
      try {
        let appointment = await getAppointmentById(reservation.appointment.id)

        reservation['appointment'] = appointment?.data !== undefined ? appointment : appointment
        selectedReservation.value['appointment'] = appointment
      } catch (error) {
        // Swal.fire({
        //   icon: 'error',
        //   title: 'The booking has been corrupted',
        //   text: 'Please delete and recreate the booking',
        //   allowOutsideClick: false,
        //   confirmButtonColor: '#03A9F4',
        // })
      }
      showBookingDetail.value = true
    }

    const editBooking = async booking => {
      if (booking?.recurringId !== null && booking?.recurringId !== undefined) {
        Swal.fire({
          icon: 'warning',
          title: 'Recurring Booking',
          text: 'You cannot edit a recurring booking. Please delete and recreate it.',
          allowOutsideClick: false,
          confirmButtonColor: '#03A9F4',
        }).finally(() => {
          showLoader.value = false
          showBookingDetail.value = false
        })
      } else {
        showLoader.value = true
        showBookingDetail.value = false
        let category = await getPoiCategoryById(selectedReservation.value?.poi?.poiCategories?.ids[0])
        showLoader.value = false
        console.log(selectedReservation.value)
        router
          .push({
            name: 'editManageBooking',
            params: {
              building_id: selectedReservation.value.poi.buildingId,
              category_id: category?.id,
              poi_id: selectedReservation.value?.poi?.id,
              reservation_id: selectedReservation.value?.id,
              appointment_id: selectedReservation.value?.appointment?.id,
            },
          })
          .catch(error => {
            console.log(error)
          })
      }
    }

    const fetchPoisList = async () => {
      try {
        console.log('fetchPoisList() called')
        let userProfile = JSON.parse(JSON.stringify(currentUserProfile.value))
        let startDate = dates.value?.start
        let endDate = dates.value?.end
        let poiReservations
        if (selectedDateRange.value === 'All') {
          startDate = new Date()
          endDate = moment().add(60, 'day').endOf('day').toDate()
          poiReservations = await getReservationsByCurrentUserByTime(startDate, endDate)
        } else {
          poiReservations = await getReservationsByCurrentUserByTime(new Date(startDate), new Date(endDate))
        }
        console.log('poiReservations', poiReservations)

        const futureRecurringIds = new Set()
        poiReservations.forEach(reservation => {
          if (reservation.recurringId && moment(reservation.endTime).isAfter(moment())) {
            futureRecurringIds.add(reservation.recurringId)
          }
        })

        poiReservations = lodash.filter(
          poiReservations,
          reservation =>
            (reservation.recurringId !== null && futureRecurringIds.has(reservation.recurringId)) ||
            (!reservation.recurringId && moment(reservation?.endTime).isSameOrAfter(moment()) && reservation?.creatorEmail === userProfile.email)
        )

        const reservationMap = {}

        for (let i = 0; i < poiReservations.length; i++) {
          const reservation = poiReservations[i]

          if (!reservation?.poiId) {
            poiReservations[i] = await getReservationById(reservation?.id)
          }

          let poi = await getPoiById(reservation?.poiId)
          reservation['poi'] = poi

          let floor = await getFloorById(poi?.floors?.ids[0])
          reservation['floor'] = floor

          if (reservation.recurringId) {
            if (!reservationMap[reservation.recurringId]) {
              reservationMap[reservation.recurringId] = {
                ...reservation,
                count: [],
              }
            } else {
              if (moment(reservation.startTime).isBefore(moment(reservationMap[reservation.recurringId].startTime))) {
                const currentCount = reservationMap[reservation.recurringId].count
                currentCount.push(reservationMap[reservation.recurringId])
                reservationMap[reservation.recurringId] = {
                  ...reservation,
                  count: currentCount,
                }
              }
            }
            reservationMap[reservation.recurringId].count.push(reservation)
            reservationMap[reservation.recurringId].count.sort((a, b) => (moment(a.startTime).isBefore(moment(b.startTime)) ? -1 : 1))
          } else {
            reservationMap[reservation.id] = reservation
          }
        }
        let filterReservations = lodash.orderBy(Object.values(reservationMap), [reservation => moment(reservation?.endTime)], ['asc'])

        if (floorChanged.value === true && selectedFloor.value?.id !== 1) {
          filterReservations = lodash.filter(filterReservations, reservation => reservation.floorName === selectedFloor.value?.name)
        }
        if (categoryChanged.value === true && selectedCategory.value.id !== 1) {
          filterReservations = lodash.filter(filterReservations, reservation => reservation.poiCategory === selectedCategory.value?.name)
        }
        reservations.value = filterReservations
      } catch (error) {
        console.log(error)
      } finally {
        showLoader.value = false
      }
    }

    const deleteReservationOnly = async reservationId => {
      try {
        await deleteReservation(reservationId)
      } catch (error) {
        console.log(error)
        showLoader.value = false
        Swal.fire({
          icon: 'error',
          title: 'Booking Deletion Failed',
          text: 'There was an error deleting the booking. Please try again.',
          allowOutsideClick: false,
          confirmButtonColor: '#03A9F4',
        })
      } finally {
        showLoader.value = false
        fetchPoisList()
      }
    }

    const deleteRecurringReservationOnly = async reservationId => {
      try {
        await deleteRecurrenceReservation(reservationId)
      } catch (error) {
        console.log(error)
        showLoader.value = false
        Swal.fire({
          icon: 'error',
          title: 'Booking Deletion Failed',
          text: 'There was an error deleting the booking. Please try again.',
          allowOutsideClick: false,
          confirmButtonColor: '#03A9F4',
        })
      } finally {
        showLoader.value = false
        fetchPoisList()
      }
    }

    const deleteConfirmation = selectedReservation => {
      let reservation = JSON.parse(JSON.stringify(selectedReservation))
      showBookingDetail.value = false
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          try {
            if (reservation?.recurringId !== null && reservation?.recurringId !== undefined) {
              deleteRecurringBooking(reservation)
            } else {
              deleteBooking(reservation)
            }
          } catch (error) {
            console.log(error)
            showLoader.value = false
            Swal.fire({
              icon: 'error',
              title: 'Booking Deletion Failed',
              text: 'There was an error deleting the booking. Please try again.',
              allowOutsideClick: false,
              confirmButtonColor: '#03A9F4',
            })
          }
        } else {
          showBookingDetail.value = true
        }
      })
    }

    const deleteBooking = async selectedReservation => {
      let appointmentId = selectedReservation?.appointment?.id
      let reservationId = selectedReservation?.id
      showLoader.value = true

      if (appointmentId !== null && appointmentId !== undefined) {
        try {
          await deleteAppointment(appointmentId)
          showBookingDetail.value = false
          await deleteReservationOnly(reservationId)
          Swal.fire({
            icon: 'success',
            text: 'Booking deletion successful',
            allowOutsideClick: false,
            confirmButtonColor: '#03A9F4',
          }).then(() => {
            fetchPoisList()
          })
        } catch (error) {
          console.log(error)
          await deleteReservationOnly(reservationId)
        }
      } else {
        try {
          await deleteReservation(reservationId)
          showBookingDetail.value = false
          Swal.fire({
            icon: 'success',
            text: 'Booking deletion successful',
            allowOutsideClick: false,
            confirmButtonColor: '#03A9F4',
          }).then(() => {
            fetchPoisList()
          })
        } catch (error) {
          console.log(error)
          Swal.fire({
            icon: 'error',
            title: 'Booking Deletion Failed',
            text: 'There was an error deleting the booking. Please try again.',
            allowOutsideClick: false,
            confirmButtonColor: '#03A9F4',
          })
          showLoader.value = false
        } finally {
          showLoader.value = false
          fetchPoisList()
        }
      }
    }

    const getWindowWidth = () => {
      windowWidth.value = document.documentElement.clientWidth
    }

    const deleteRecurringBooking = async selectedReservation => {
      let appointmentId = selectedReservation?.appointment?.recurringId
      let reservationId = selectedReservation?.recurringId
      showLoader.value = true

      if (appointmentId !== null && appointmentId !== undefined) {
        try {
          await deleteRecurrenceAppointment(appointmentId)
          showBookingDetail.value = false
          await deleteRecurringReservationOnly(reservationId)
          Swal.fire({
            icon: 'success',
            text: 'Booking deletion successful',
            allowOutsideClick: false,
            confirmButtonColor: '#03A9F4',
          }).then(() => {
            fetchPoisList()
          })
        } catch (error) {
          console.log(error)
          await deleteRecurringReservationOnly(reservationId)
        }
      } else {
        try {
          await deleteRecurrenceReservation(reservationId)
          showBookingDetail.value = false
          Swal.fire({
            icon: 'success',
            text: 'Booking deletion successful',
            allowOutsideClick: false,
            confirmButtonColor: '#03A9F4',
          }).then(() => {
            fetchPoisList()
          })
        } catch (error) {
          console.log(error)
          Swal.fire({
            icon: 'error',
            title: 'Booking Deletion Failed',
            text: 'There was an error deleting the booking. Please try again.',
            allowOutsideClick: false,
            confirmButtonColor: '#03A9F4',
          })
          showLoader.value = false
        } finally {
          showLoader.value = false
          fetchPoisList()
        }
      }
    }

    onMounted(async () => {
      try {
        showLoader.value = true
        nextTick(() => {
          window.addEventListener('resize', getWindowWidth)
          getWindowWidth()
        })

        dateOptions.value = ['All', 'Today', 'Tomorrow', 'Current Week', 'Next Week', 'Current Month', 'Next Month']

        window.addEventListener('resize', handleResize)

        const [userProfile, buildingsData, poiCategoriesData, poiFloorsData] = await Promise.all([
          OAuthService.getUserProfile(),
          getBuildings(),
          getPoiCategories(),
          getFloors(),
        ])

        currentUserProfile.value = userProfile
        buildings.value = buildingsData

        let poiCategories = lodash.filter(
          lodash.orderBy(poiCategoriesData, 'name', 'asc'),
          category => category.active === true && !category.name.includes('Room Control')
        )
        poiCategories.unshift({ id: 1, name: 'All' })

        let poiFloors = lodash.filter(lodash.orderBy(poiFloorsData, 'name', 'asc'), floor => floor.active === true)
        poiFloors.unshift({ id: 1, name: 'All' })

        categories.value = poiCategories
        floors.value = poiFloors
        selectedFloor.value = poiFloors[0]
        selectedCategory.value = poiCategories[0]
        selectedDateRange.value = 'Current Month'

        onDateRangeChange()
      } catch (error) {
        console.log(error)
      } finally {
        showLoader.value = false
      }
    })

    watch(
      selectedDateRange,
      newValue => {
        // Handle selectedDateRange change
      },
      { deep: true }
    )

    watch(showDeleteBookingsDialog, newValue => {
      if (newValue) {
        selectedReservationTitles.value = [...selectedReservations.value]
      }
    })

    const maxDate = computed(() => {
      const today = new Date()
      return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 56)
    })

    const isMobile = computed(() => {
      return windowWidth.value <= 600 // Assuming mobile screens have a width of 600px or less
    })

    const calendarNudgeTop = computed(() => {
      return isMobile.value ? 120 : 0 // Adjust -250 (or another value) until the position is satisfactory
    })

    const handleResize = () => {
      windowWidth.value = window.innerWidth
    }

    onUnmounted(() => {
      window.removeEventListener('resize', getWindowWidth)
    })

    return {
      menu,
      showDatePicker,
      selectedReservationTitles,
      showDeleteBookingsDialog,
      selectedReservations,
      dateRangeDialog,
      pressTimer,
      dialog,
      dates,
      date,
      dateRangeMenuVisible,
      dateOptions,
      dateDisplayText,
      selectedDateRange,
      timezone,
      filterDialogVisible,
      startDate,
      endDate,
      startDateMenu,
      endDateMenu,
      categories,
      selectedCategory,
      selectedFloor,
      floors,
      selected,
      startDateDialogVisible,
      showFilterDialog,
      selectedKey,
      items,
      bookings,
      windowWidth,
      showNoBookings,
      reservations,
      appointments,
      currentUserProfile,
      buildings,
      showLoader,
      showBookingDetail,
      showEditBooking,
      selectedReservation,
      filters,
      originalFilters,
      originalReservations,
      categoryChanged,
      floorChanged,
      dateChanged,
      discardRange,
      confirmRange,
      handeReservation,
      isSelected,
      cancelFilter,
      filterChange,
      resetCustomRangeDate,
      onDateRangeChange,
      toggleFilterDialog,
      applyFilters,
      changeCategory,
      changeFloor,
      goToBookings,
      bookingDetail,
      editBooking,
      fetchPoisList,
      deleteReservationOnly,
      deleteRecurringReservationOnly,
      deleteConfirmation,
      deleteBooking,
      getWindowWidth,
      deleteRecurringBooking,
      maxDate,
      isMobile,
      calendarNudgeTop,
      imageSrcMobile,
      imageSrcDesktop,
      handleResize,
      router,
      moment,
    }
  },
}
</script>

<style scoped>
.menuable__content__active {
  z-index: 3000 !important;
}

.booking-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 0.8rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.booking-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-header {
  background-color: lightgrey;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.button-container {
  display: flex;
  gap: 4px;
}

.styled-btn {
  background-color: #414a4c;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.styled-btn:hover {
  background-color: #30363a;
  transform: translateY(-2px);
}

.card-body {
  padding: 10px;
}

.info-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.info-item {
  display: flex;
  align-items: center;
}

.v-icon {
  margin-right: 8px;
}

.text {
  font-size: 14px;
  color: #333;
}

.no_booking_create {
  background-color: white;
  color: black;
  border: 2px solid black;
  font-weight: bold;
  margin-bottom: -1rem;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  top: 65px;
  position: absolute;
  left: 20px;
  font-weight: bold;
}

.no_booking_create:hover {
  background-color: black;
  color: white;
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.fixed-btn {
  position: fixed;
  right: 36px;
  bottom: 65px;
  z-index: 999;
}

.fixed-btn-delete {
  position: fixed;
  right: 36px;
  bottom: 130px;
  z-index: 1000;
}

.no_booking_text {
  z-index: 1;
  position: absolute;
  color: white;
  font-size: 24px;
  font-weight: bold;
  background: black;
  width: 100%;
}

.booking-list {
  margin: 0 auto;
}

.booking-time {
  margin-top: 0.5rem;
}

.booking-start-time {
  background-color: lightgray;
  line-height: 0.5rem;
  font-size: 1rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.reservation-start-time {
  border-right: 0.1px solid;
}

.booking-reservation {
  margin-top: -1.5rem;
}

.reservation-end-time {
  color: gray;
}

.reservation-poi {
  color: black;
  font-size: 0.9rem;
  font-weight: 400;
}

.card {
  box-shadow: -1px 10px 39px -4px rgba(0, 0, 0, 0.89);
  -webkit-box-shadow: -1px 10px 39px -4px rgba(0, 0, 0, 0.89);
  -moz-box-shadow: -1px 10px 39px -4px rgba(0, 0, 0, 0.89);
  text-align: center;
}
</style>
<!-- UserBookings Component

This component provides a comprehensive interface for users to manage their bookings. It includes functionalities for viewing, filtering, and managing reservations.

At the top of the component, there is a delete button that appears if the user has selected any reservations. This button allows users to delete multiple bookings at once.

A Loader component is used to indicate loading states, ensuring users are aware when data is being fetched or processed.

The date range selector allows users to filter bookings based on predefined date ranges such as Today, Tomorrow, Current Week, Next Week, Current Month, Next Month, or a Custom range. Users can select a date range from a dropdown menu, and if 'Custom' is selected, a date picker dialog appears for users to specify the start and end dates.

A filter button next to the date range selector opens a dialog where users can filter bookings by category and floor. This helps users narrow down their search to specific types of bookings or locations.

If there are no bookings, a card is displayed with a message indicating no bookings are available. This card includes an image and a button that redirects users to the booking creation page.

Each booking is displayed as a card with details such as the date, time, and location. Clicking on a booking card opens a detailed view of the booking in a dialog. This detailed view includes options to delete or edit the booking. If the booking is corrupted, a message is displayed advising the user to delete and recreate the booking.

The component uses Swal for displaying alerts. For example, when a booking is successfully deleted, a success alert is shown. If there is an error during deletion, an error alert is displayed. These alerts provide immediate feedback to users about the actions they have performed.

Overall, this component enhances the user experience by providing a clear and interactive interface for managing bookings, with visual feedback and easy-to-use filtering options. -->

<template>
    <div class="users-list">
        <Loader :loading="showLoader" />
        <v-toolbar class="custom-toolbar mb-4" flat dense>
            <v-btn icon @click="goBack">
                <v-icon size="40">mdi-arrow-left-bold-circle</v-icon>
            </v-btn>
            <v-toolbar-title class="custom-toolbar-title">
                {{ 'Users' }}
            </v-toolbar-title>
            <v-btn class="mr-1" icon small @click="refresh">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn size="small" class="pulse-animation" variant="tonal" @click="openCreateUser" color="black">Create
                new</v-btn>
        </v-toolbar>

        <v-container class="mt-2 mb-0" style="background-color: transparent; box-shadow: none">
            <v-card-text>
                <v-row align="center" class="mb-2">
                    <v-col>
                        <v-text-field v-model="searchQuery" label="Search" variant="outlined"
                            prepend-inner-icon="mdi-magnify" dense class="mx-4" style="margin-bottom: 0"></v-text-field>
                    </v-col>
                </v-row>
                <div class="chip-row">
                    <div class="chip" :class="{ selected: selectedFilter === 'all' }" @click="filterUsers('all')">All
                        Users</div>
                    <div class="chip" :class="{ selected: selectedFilter === 'employee' }"
                        @click="filterUsers('employee')">Employees</div>
                    <div class="chip" :class="{ selected: selectedFilter === 'guest' }" @click="filterUsers('guest')">
                        Guests</div>
                    <div class="chip" :class="{ selected: selectedFilter === 'globaladmin' }"
                        @click="filterUsers('globaladmin')">
                        Administrators</div>
                </div>
            </v-card-text>
        </v-container>
        <AdminUsers :users="filteredUsers" :onEdit="openEditUser" :onDelete="deleteUser" />
    </div>
</template>

<script>
import { ref, onMounted, defineComponent, computed } from 'vue';
import { debounce } from 'lodash';
import Loader from '@/components/general/Loader.vue';
import { getToolbarLogoImage } from '@/assets/Branding/branding.js';
import { useRouter } from 'vue-router';
import AdminUsers from '@/components/general/ChildComponents/AdminUsers.vue';
import { getUsers } from '@/controllers/BackboneAPI.js';
import { getUserProfiles, getCampusById, getCampuses, storeData } from '@/controllers/BaseController';
import lodash from 'lodash';
import { dbPromise } from '@/services/utils';
import { removeStoredItem } from '@/services/utils';
import Swal from 'sweetalert2';

export default defineComponent({
    name: 'UsersList',
    components: {
        Loader,
        AdminUsers,
    },
    setup() {
        const showLoader = ref(false);
        const router = useRouter();
        const logo = ref('');
        const searchQuery = ref('');
        const USERS = ref([]);
        const originalUsers = ref([]);
        const filteredUsers = computed(() => {
            const query = searchQuery.value.toLowerCase();
            return USERS.value.filter(user => {
                const matchesSearchQuery = (
                    (user.firstName && user.firstName.toLowerCase().includes(query)) ||
                    (user.lastName && user.lastName.toLowerCase().includes(query)) ||
                    (user.email && user.email.toLowerCase().includes(query))
                );

                // Check if the user matches the selected role filter
                const matchesRoleFilter =
                    selectedFilter.value === 'all' || user.roles?.includes(selectedFilter.value);

                return matchesSearchQuery && matchesRoleFilter;
            });
        });

        const selectedFilter = ref('all');

        const goBack = () => {
            window.history.back();
        };

        const filterUsers = (filter) => {
            selectedFilter.value = filter;
            // let users = JSON.parse(JSON.stringify(originalUsers.value));
            // if (filter !== 'all') {
            //     users = originalUsers.value;
            // }
            // else {
            //     users = lodash.filter(users, (user) => user.roles?.includes(filter));
            // }
            // console.log(filter)
            // USERS.value = users;
        };

        const navigateTo = (routeName, params = {}) => {
            router.push({ name: routeName, params });
        };

        const openCreateUser = () => {
            navigateTo('new-user');
        };

        const openEditUser = async (user) => {
            try {
                showLoader.value = true;
                user = JSON.parse(JSON.stringify(user));
                let UserId = user.id;
                await storeData(UserId, user);
                navigateTo('edit-user', { id: UserId });
            } catch (error) {
                console.error('Failed to load data:', error);
                showLoader.value = false;

            }
        };
        const deleteUser = async (user) => {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: `Do you want to delete the user ${user.email}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d2f8d2',
                cancelButtonColor: '#fbe5e3',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
            });

            if (result.isConfirmed) {
                try {
                    console.log('Deleting user:', user);
                    // Add your actual delete logic here, e.g., calling an API to delete the user from the server or database
                    Swal.fire({
                        icon: 'success',
                        title: 'Deleted!',
                        text: `User ${user.name} has been deleted.`,
                        timer: 2000,
                        showConfirmButton: false,
                    });
                } catch (error) {
                    console.error('Error deleting user:', error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'There was a problem deleting the user. Please try again later.',
                    });
                }
            } else {
                Swal.close(); // Close the Swal dialog if canceled
            }
        };

        const resizeListener = debounce(() => window.innerWidth, 200);

        // Function to save users to IndexedDB
        const saveUsersToCache = async (users) => {
            try {
                const db = await dbPromise;
                const tx = db.transaction('users', 'readwrite');
                const store = tx.store;

                // Clear existing users in the store
                await store.clear();

                // Add the new users to the store
                for (const user of users) {
                    await store.put(user);
                }

                await tx.done;
                console.log('Users data saved to IndexedDB');
            } catch (error) {
                console.error('Error saving users to IndexedDB:', error);
            }
        };

        const refresh = async () => {
            // Display Swal loader while deleting users
            Swal.fire({
                title: 'Refreshing...',
                text: 'Please wait while we refresh the users list.',
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            try {
                // Call the function to delete all stored users and user profiles
                await deleteUsersFromCache();

                // Reload the current page to refresh the users list
                router.go(0);
            } catch (error) {
                console.error('Error refreshing users:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Refresh Failed',
                    text: 'There was an issue refreshing the users. Please try again.',
                });
            }
        };

        onMounted(async () => {
            Swal.fire({
                title: 'Loading...',
                text: 'Please wait while we fetch and process user data.',
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            try {
                logo.value = await getToolbarLogoImage();
                window.addEventListener('resize', resizeListener);
                resizeListener();

                // Fetch users, profiles, and campuses
                let users = await getUsers();
                let userProfiles = await getUserProfiles();
                let campuses = await getCampuses();

                // Process each user
                users.forEach((user) => {
                    let profile = userProfiles.find((profile) => profile.email === user.email);
                    if (profile) {
                        user.profile = profile;

                        if (profile?.campus?.id) {
                            let campus = campuses.find((campus) => campus.id === profile.campus?.id);
                            user.campusName = campus?.name;
                        } else {
                            user.campusName = 'null';
                        }
                    } else {
                        user.profile = {};
                    }
                });

                // Sort users alphabetically by first name and place those with null/empty first names at the bottom
                users = lodash.orderBy(users, [(user) => (user.firstName ? user.firstName.toLowerCase() : 'zzzz')], ['asc']);
                console.log('Users:', users);

                USERS.value = users;
                originalUsers.value = users;
                // Save sorted users to IndexedDB
                await saveUsersToCache(users);

                Swal.close(); // Close the Swal loader once processing is done
            } catch (error) {
                console.error('Failed to load data:', error);

                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to load user data. Please try again later.',
                });
            }
        });

        const deleteUsersFromCache = async () => {
            const db = await dbPromise;
            const tx = db.transaction('users', 'readwrite');
            const store = tx.store;

            // Delete all keys from IndexedDB 'users' store
            const keys = await store.getAllKeys();
            for (const key of keys) {
                await store.delete(key);
                console.log(`Deleted cache entry for key: ${key}`);
            }
            await tx.done;
            console.log('All user-related data deleted from the users store.');

            // Clear additional stored data for 'userProfiles' and 'users'
            await removeStoredItem('userProfiles');
            await removeStoredItem('users');
            console.log('userProfiles and users data cleared from storage.');
        };

        return {
            showLoader,
            logo,
            router,
            searchQuery,
            openCreateUser,
            goBack,
            openEditUser,
            deleteUser,
            USERS,
            deleteUsersFromCache,
            saveUsersToCache,
            refresh,
            filteredUsers,
            selectedFilter,
            filterUsers,

        };
    },
});

</script>

<style scoped>
.users-list {
    padding: 1rem;
}

.custom-toolbar {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    color: #333;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
}

.custom-toolbar-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #333;
    margin-left: 10px;
}

.user-card {
    cursor: pointer;
    transition: transform 0.2s ease;
}

.user-card:hover {
    transform: scale(1.02);
}

.chip-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    /* Controls the gap between chips */
    margin: 16px 0;
    padding: 0 8px;
}

.chip {
    font-size: 1rem;
    padding: 8px 12px;
    border-radius: 20px;
    background-color: #D5D5D6;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    min-width: 80px;
    /* Ensures all chips have similar width */
    flex-shrink: 1;
}

.chip.selected {
    background-color: #4caf50;
    color: #fff;
    font-weight: bold;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Hover effect */
.chip:hover {
    background-color: #4caf50;
    /* Change to a color of your choice */
    color: #fff;
    transform: scale(1.05);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .chip {
        font-size: 0.9rem;
        padding: 6px 10px;
    }
}

@media (max-width: 480px) {
    .chip {
        font-size: 0.8rem;
        padding: 4px 8px;
        min-width: 70px;
    }
}

@media (max-width: 400px) {
    .chip {
        font-size: 0.75rem;
        padding: 2px 6px;
        min-width: 60px;
    }
}
</style>

<!-- UsersList Component
This component provides a user interface for managing a list of users. It includes functionalities for searching, filtering, creating, editing, and deleting users. 

The top toolbar contains a back button, a title, a refresh button, and a button to create a new user. The back button allows users to navigate to the previous page. The refresh button reloads the user list, and the create new button opens a form to add a new user.

Below the toolbar, there is a search input field where users can type to filter the user list based on the entered query. The search is case-insensitive and matches the first name, last name, or email of the users.

The component also includes filter chips that allow users to filter the list by different user roles such as all users, employees, guests, and administrators. Clicking on a chip will filter the user list accordingly and highlight the selected chip.

The main content area displays a list of users using the AdminUsers component. Each user card in the list includes options to edit or delete the user. Clicking the edit button opens the edit form for the selected user. Clicking the delete button triggers a confirmation dialog using Swal. If confirmed, the user is deleted, and a success message is shown. If there is an error during deletion, an error message is displayed.

Visual feedback is provided using Swal for various actions. For example, a loading dialog is shown while fetching user data, and success or error alerts are displayed based on the outcome of actions like deleting a user or refreshing the list.

Overall, this component enhances the user experience by providing an intuitive and interactive interface for managing users within the application. -->

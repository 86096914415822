<template>
    <div class="roles-list">
        <Loader :loading="showLoader" />
        <v-toolbar class="custom-toolbar mb-4" flat dense>
            <v-btn icon @click="goBack">
                <v-icon size="40">mdi-arrow-left-bold-circle</v-icon>
            </v-btn>
            <v-toolbar-title class="custom-toolbar-title">
                {{ 'Roles' }}
            </v-toolbar-title>
            <v-btn class="mr-1" icon small @click="refresh">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn size="small" class="pulse-animation" variant="tonal" @click="CreateEditRole" color="black">Create
                new</v-btn>
        </v-toolbar>

        <v-container class="mt-2 mb-0" style="background-color: transparent; box-shadow: none">
            <v-card-text>
                <v-row align="center" class="mb-2">
                    <v-col cols="auto">
                        <!-- <v-btn icon @click="menu = !menu" color="primary" style="margin-top: -1rem">
                            <v-icon>{{ menu ? 'mdi-menu-open' : 'mdi-menu' }}</v-icon>
                        </v-btn> -->
                    </v-col>
                    <v-col>
                        <v-text-field v-model="searchQuery" label="Search" variant="outlined"
                            prepend-inner-icon="mdi-magnify" dense class="mx-4" style="margin-bottom: 0"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-container>
    </div>
</template>

<script>
import { ref, onMounted, defineComponent } from 'vue'
import { debounce } from 'lodash'
import Loader from '@/components/general/Loader.vue'
import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
import { useRouter } from 'vue-router'

export default defineComponent({
    name: 'RolesList',
    components: {
        Loader,
    },
    setup() {
        const showLoader = ref(true)
        const router = useRouter()
        const logo = ref('')

        const goBack = () => {
            window.history.back()
        }

        const navigateTo = (routeName, params = {}) => {
            router.push({ name: routeName, params })
        }

        const CreateEditRole = () => {
            navigateTo('new-role')
        }

        const resizeListener = debounce(() => (window.innerWidth), 200)

        onMounted(async () => {
            try {
                showLoader.value = true
                logo.value = await getToolbarLogoImage()
                window.addEventListener('resize', resizeListener)
                resizeListener()
                showLoader.value = false
            } catch (error) {
                console.error('Failed to load data:', error)
                showLoader.value = false
            }
        })

        return {
            showLoader,
            logo,
            router,
            goBack,
            navigateTo,
            CreateEditRole,
        }
    },
})
</script>

<style scoped>
.roles-list {
    padding: 1rem;
}

.custom-toolbar {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    color: #333;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
}

.custom-toolbar-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #333;
    margin-left: 10px;
}

.toolbar-icon {
    height: 30px;
}
</style>
<!-- RolesList Component

This component provides a user interface for managing roles within the application. It includes several interactive elements and functionalities to enhance user experience.

The main features of this component are:

1. Loader: A loading indicator that is displayed while data is being fetched or processed. It provides visual feedback to the user, indicating that an operation is in progress.

2. Toolbar: The toolbar at the top of the component includes the following elements:
    - Back Button: An icon button that allows users to navigate back to the previous page.
    - Title: Displays the title "Roles" to indicate the current section.
    - Refresh Button: An icon button that allows users to refresh the list of roles.
    - Create New Button: A button that navigates users to a form for creating a new role.

3. Search Field: A text field that allows users to search for specific roles. It includes a magnifying glass icon to indicate its purpose.

4. Dynamic Behaviors:
    - The component listens for window resize events and adjusts its layout accordingly.
    - The loader is shown while data is being fetched and hidden once the data is loaded.

5. Visual Feedback:
    - Success, warning, or error alerts can be implemented using libraries like Swal to provide feedback based on user actions or events. For example, an error alert can be shown if data fails to load.

Users can interact with this component by:
- Clicking the back button to return to the previous page.
- Using the refresh button to reload the list of roles.
- Clicking the "Create new" button to navigate to the role creation form.
- Typing in the search field to filter the list of roles based on the search query.

Overall, this component provides a user-friendly interface for managing roles, with clear visual feedback and intuitive navigation options.

-->
